import type {
  BetOfferResponse,
  EventResponse,
  GroupResponse,
  ListView,
  LiveDataResponseMultiEventDto,
} from './__generated__/offering';

type ResponseType = BetOfferResponse | GroupResponse | LiveDataResponseMultiEventDto | ListView | EventResponse;

export async function fetchOffering<T extends ResponseType>(query: string | URL) {
  const url = new URL(query);

  if (!url.pathname.endsWith('.json')) {
    url.pathname += '.json';
  }

  // https://feeds.kambi.com/offering/v2018/docs/#/docs/restapi/parameters

  // Forces the API to return a 200 status code even if there's no bet offers
  // and allows us to mask the error handling
  url.searchParams.set('suppress_response_codes', '1');

  // Prevents accidental uses of JSONP function
  url.searchParams.delete('callback');

  const response = await fetch(url, {
    headers: { accept: 'application/json' },
    method: 'GET',
    mode: 'cors',
    credentials: 'omit',
    referrerPolicy: 'no-referrer',
  });

  if (!response.ok) {
    // Network error
    const cause = {
      url: response.url,
      status: response.status,
      statusText: response.statusText,
    };
    throw new Error(`Unexpected response`, { cause });
  }

  const data = await response.json();

  if (isErrorResponse(data)) {
    const cause = {
      headers: response.headers,
      status: data.error.status,
      statusText: data.error.message,
    };
    throw new Error(data.error.message, { cause });
  }

  return data as T;
}

interface ErrorResponse {
  error: {
    message: string;
    status: number;
  };
}

function isErrorResponse(response: unknown): response is ErrorResponse {
  return typeof response === 'object' && response != null && 'error' in response;
}
