/** @see [Adding bets to the betslip through a deep-link](https://www.kambipartner.com/techhub/api_docs/gen_doc/client_documentation/deeplinking_in_the_html5_client) */

type CouponType =
  | 'single'
  | 'combination'
  | 'system'
  | 'patent'
  | 'trixie'
  | 'yankee'
  | 'canadian'
  | 'heinz'
  | 'superheinz';

/**
 * @description list of outcome ids (comma separated integer values) or combined ids as a string for playercast and multiposition.
 * @example
 *    42149959
 *    '42149959,42124511,42042573'
 *    '2006645924_2006645639_playercast,2006646785_1001964741_1001895616_forecast'
 */
type Outcomes = number | string;

/**
 * @description
 *    Stake values (comma separated float values). Multiple values are used for system coupons.
 *
 *    If {@link CouponType} is "patent", use one stake value.
 *
 *    For all other bet packages (trixie, yankee, canadian etc), the first value is used for singles and the second is used for the remaining stake input fields.
 * @example
 *    3.75,7
 */
type Stakes<T = string | number> = T extends '' ? never : T;

/**
 * @description
 *    The update mode of the coupon. Can be either "append" or "replace".
 * @example
 *    'append'  adds the outcomes to the current coupon (betslip).
 *    'replace' - replace the current coupon (betslip).
 */
type UpdateMode = 'append' | 'replace';

/**
 * @description
 *    An optional text parameter stating the source of the deep-link.
 *
 *    The source could be made up of any text not including the "pipe" character ("|") which is reserved for the deep-linking format
 *
 *    The source is added to the "Betting / Add bet / Source" event in Google Analytics, so that it is possible to see how often it is used to add bets to the betslip.
 * @example
 *    "Christmas campaign - The Daily Gazette - Large Banner"
 *    "Top Bets - Casino"
 */
type Source<T = string> = T extends '' ? never : T;

/**
 * @example
 *    `combination|42036595|3.14|append`
 *    `combination|41712453,41985948,42036595|10|append`
 *    `combination|41712453,41985948,42036595|5.5|replace|Home page banner`
 *    `system|41712453,41985948,42036595|5.5,4,6|replace|Newspaper campaign 399225`
 *    `trixie|41712453,41985948,42036595|0,4|replace`
 *    `single|2006645924_2006645639_playercast,2006646785_1001964741_1001895616_forecast,2006536623|replace`
 */
export type Coupon = `${CouponType}|${Outcomes}|${Stakes}|${UpdateMode}|${Source}`;

export function buildCoupon(
  type: CouponType,
  outcomes: Outcomes,
  stakes: Stakes,
  mode: UpdateMode,
  source: Source,
): Coupon {
  return `${type}|${outcomes}|${stakes}|${mode}|${source}` as const;
}
