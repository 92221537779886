import type { BetOfferResponse } from './__generated__/offering';
import { compareEvent, compareNumber } from './sort';

export function formatBetOfferResponse(betOfferResponse: BetOfferResponse) {
  // Sort events by start date, group sort order then sort order
  const events = Array.from(betOfferResponse.events).sort(compareEvent);

  // Fast lookup for event order
  const eventIndex = new Map<number, number>(events.map((event, index) => [event.id, index]));

  // Sort bet offers by event order
  const betOffers = Array.from(betOfferResponse.betOffers).sort((a, b) => {
    return compareNumber(eventIndex.get(a.eventId), eventIndex.get(b.eventId));
  });

  return {
    events,
    betOffers,
  };
}
