interface Group {
  id: number;
  name: string;
  sport: string;
  groups: Group[];
}

export interface GroupResponse {
  id: 1;
  sport: 'NOT-SPECIFIED';
  groups: SportGroup[];
}

export interface SportGroup extends Group {
  groups: (RegionGroup | LeagueGroup)[];
}

export interface RegionGroup extends Group {
  groups: LeagueGroup[];
}

export interface LeagueGroup extends Group {
  groups: [];
}

interface KambiFetchParams {
  offering: string;
  signal?: AbortSignal;
}

export async function fetchGroups({ offering, signal }: KambiFetchParams) {
  const url = new URL(`https://feeds.kambi.com/feeds/api/${offering}/group.json`);

  const response = await fetch(url, {
    headers: { accept: 'application/json' },
    method: 'GET',
    mode: 'cors',
    credentials: 'omit',
    referrerPolicy: 'no-referrer',
    signal,
  });

  const groupResponse: GroupResponse = await response.json();

  return groupResponse;
}
