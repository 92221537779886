import type { BetOfferResponse } from './__generated__/offering';
import { compareEvent } from './sort';

export function filterBetOfferResponseByTag(betOfferResponse: BetOfferResponse) {
  // Sort events by start date, group sort order then sort order
  const events = Array.from(betOfferResponse.events).sort(compareEvent);

  const filteredBetOffer = Array.from(betOfferResponse.betOffers).find((betOffer) => betOffer.tags.includes('MAIN'));

  return {
    events,
    betOffers: filteredBetOffer ? [filteredBetOffer] : [],
  };
}
