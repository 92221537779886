import { compareAsc } from 'date-fns';
import type { Event } from './__generated__/offering';

export function compareEvent(a: Event, b: Event) {
  return (
    // Event start date and time. See Data Formats for the formatting.
    compareISODate(a.start, b.start) ||
    // The sort order of the event group for this event. With low number ranked higher.
    compareNumber(a.groupSortOrder, b.groupSortOrder) ||
    // Sort order of the event within its event group, administrated through Presentation Manager.
    compareNumber(a.sortOrder, b.sortOrder)
  );
}

export function compareISODate(a: string, b: string) {
  return compareAsc(a, b);
}

export function compareNumber(a?: number, b?: number) {
  return Number(a) - Number(b);
}
