import type { BetOfferResponse } from './__generated__/offering';

export const randomBetOfferResponse = (betOfferResponse: BetOfferResponse) => {
  const randomEvent = betOfferResponse.events[Math.floor(Math.random() * betOfferResponse.events.length)];

  const betOffer = betOfferResponse.betOffers.find((betOffer) => betOffer.eventId === randomEvent?.id);

  return {
    events: randomEvent ? [randomEvent] : [],
    betOffers: betOffer ? [betOffer] : [],
  };
};
