import type { Outcome } from './__generated__/offering';

const OutcomeLabel = new Map<Outcome['type'], string>([
  ['OT_ONE', '1'],
  ['OT_CROSS', 'X'],
  ['OT_TWO', '2'],
]);

function formatOutcome(outcome: Outcome) {
  return {
    ...outcome,
    label: OutcomeLabel.get(outcome.type) ?? outcome.label,
    odds: formatOdds(outcome.odds),
  };
}

export function formatOutcomes(outcomes: Outcome[]) {
  return outcomes.map(formatOutcome);
}

export function formatOdds(odds: Outcome['odds']) {
  return odds ? (odds / 1000).toFixed(2) : undefined;
}
